<template>
  <tree-container
    ref="tree"
    :data="data"
    :selectable="true"
    :tab-index="0"
    class-name="tree"
    :should-load-nodes="shouldLoadNodes"
    :should-select-node="shouldSelectNode"
    :on-cluster-did-change="onClusterDidChange"
    :on-content-did-update="onContentDidUpdate"
    :on-content-will-update="onContentWillUpdate"
    :on-open-node="onOpenNode"
    :on-close-node="onCloseNode"
    :on-select-node="onSelectNode"
    :on-will-open-node="onWillOpenNode"
    :on-will-close-node="onWillCloseNode"
    :on-will-select-node="onWillSelectNode"
    :on-key-down="onKeyDown"
    :on-key-up="onKeyUp"
    @on-view-click="onViewClick"
    @on-check-click="onCheckClick"
  />
</template>

<script>
import flatmapdeep from 'lodash.flatmapdeep';
import TreeContainer from './TreeContainer';

export default {
  name: 'InfiniteTree',
  components: {
    TreeContainer,
    // Icon,
  },
  props: {
    data: {
      type: [Array, Object],
      default: () => []
    },
    filter: {
      type: String,
      default: ''
    },
    filterFunction: {
      type: Function,
      default: (node, keyword) => node.text.toLowerCase().indexOf(keyword.toLowerCase()) >= 0 || node.id.toLowerCase().indexOf(keyword.toLowerCase()) >= 0
    }
  },
  data() {
    return {
      node: null,
      tree: null,
    };
  },
  watch: {
    filter: {
      handler(newValue) {
        this.handleFilter(newValue);
      },
    },
    /* 'tree.nodes.length': {
        handler(newValue) {
            if (newValue) {
                this.$emit('nodes-changed');
            }
        },
    } */
  },
  mounted() {
    this.tree = this.$refs.tree.tree;
  },
  methods: {
    flatten(item) {
      return [item, flatmapdeep(item.children, this.flatten)];
    },
    rerender() {
      this.tree.nodes.forEach((node, idx) => {
        this.tree.rows[idx] = this.tree.options.rowRenderer(node, this.tree.options);
      });

      this.tree.update();
    },
    handleFilter(keyword) {
      if (!keyword) {
        this.tree.unfilter();
        return;
      }

      this.tree.filter((node) => this.filterFunction(node, keyword));
    },
    selectNode(node) {
      if (node) {
        const newNode = this.tree.getNodeById(node.id);
        this.tree.selectNode(newNode);
      }
    },
    nodeStyle(node) {
      return {
        'line-height': '30px',
        background: node.state.selected ? '#deecfd' : '#fff',
        border: node.state.selected ? '1px solid #06c' : '1px solid #fff',
        'padding-left': `${(node.state.depth * 18).toString()}px`
      };
    },
    shouldLoadNodes(node) {
      return !node.hasChildren() && node.loadOnDemand;
    },
    shouldSelectNode(node) { // Defaults to null
      if (!node || (node === this.$refs.tree.tree.getSelectedNode())) {
        return false; // Prevent from deselecting the current node
      }
      return true;
    },
    /* onUpdate(node) {
        // this.node = node;
    }, */
    onKeyUp() {
      // console.log('onKeyUp');
    },
    onKeyDown() {
      // console.log('onKeyDown');
    },
    onMouseLeave() {
      // console.log('onMouseLeave');
    },
    onMouseEnter() {
      // console.log('onMouseEnter');
    },
    onClusterDidChange() {
      // console.log('on-cluster-did-change');
      // this.$emit('on-cluster-did-change');
    },
    onContentWillUpdate() {
      // console.log('onContentWillUpdate');
    },
    onContentDidUpdate() {
      // console.log('onContentDidUpdate');
    },
    // eslint-disable-next-line no-unused-vars
    onOpenNode(node) {
      // console.log('onOpenNode:', node);
      this.$emit('on-open-node', node);
    },
    // eslint-disable-next-line no-unused-vars
    onCloseNode(node) {
      // console.log('onCloseNode:', node);
    },
    onSelectNode(node) {
      // console.log('onSelectNode:', node);
      this.$emit('node-selected', node);
    },
    // eslint-disable-next-line no-unused-vars
    onWillOpenNode(node) {
      // console.log('onWillOpenNode:', node);
    },
    // eslint-disable-next-line no-unused-vars
    onWillCloseNode(node) {
      // console.log('onWillCloseNode:', node);
    },
    // eslint-disable-next-line no-unused-vars
    onWillSelectNode(node) {
      // console.log('onWillSelectNode:', node);
    },
    onViewClick(node) {
      // console.log('onViewClick:', node);
      this.$emit('on-view-click', node);
    },
    onCheckClick(node) {
      this.$emit('on-check-click', node);
    },
    toggleNodes() {
      const openNodes = this.tree.getOpenNodes();

      if (openNodes.length < 1) {
        this.tree.openAllNodes();
      } else {
        this.tree.collapseAllNodes();
      }
    },
    isNodesOpen() {
      const openNodes = this.tree.getOpenNodes();

      if (openNodes.length < 1) {
        return false;
      } return true;
    }
  }
};
</script>

<style lang="scss" scoped>

</style>
