export default {
  methods: {
    getSelectedValues(arr, value, selector, mapper) {
      return arr.filter((el) => selector(el).toLowerCase().indexOf((value.toLowerCase())) >= 0).map(mapper);
    },

    getSelectedValuesByName(arr, value) {
      return this.getSelectedValues(arr, value, ((el) => el.name), ((el) => el.name));
    },

    getSelectedValuesByCode(arr, value) {
      return this.getSelectedValues(arr, value, ((el) => el.code), ((el) => el.code));
    },

    getSelectedIdsByName(arr, value) {
      return this.getSelectedValues(arr, value, ((el) => el.name), ((el) => el.id));
    }
  }
};
